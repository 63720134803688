// Ref: https://reactjs.org/docs/context.html
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import locales, { defaultLocale, LocaleEnum } from '../../../locales';

type LocaleContext = {
  /**
   * The current locale the user is at
   */
  locale: LocaleEnum;
  /**
   * Updates the locale
   */
  setLocale: (locale: LocaleEnum) => void;
  hasProvider: boolean;
};

const initialState: LocaleContext = {
  locale: defaultLocale,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLocale: () => {},
  hasProvider: false
};

const LocaleContext = React.createContext<LocaleContext>(initialState);

export type LocaleProviderProps = { locale: LocaleEnum };

/**
 * The Locale provider for its react context
 */
export const LocaleProvider: React.FC<LocaleProviderProps> = ({ locale: propLocale, children }) => {
  // Get the locale from the query
  const {
    query: { locale: queryLocale }
  } = useRouter();

  // Only run this once at startup
  const finalLocale = useMemo(() => {
    // Prefer the locale in the router, if not available, get the locale given as prop, lastly, get the initial state locale, which is the default locale
    const locale = (queryLocale as LocaleEnum | undefined) || propLocale || initialState.locale;

    if (locale && Object.keys(locales).findIndex((x) => x === locale) === -1) {
      console.warn(
        `LocaleProvider: Missing locale data for locale: "${queryLocale}". Using default locale: "${defaultLocale}" as fallback.`
      );
      return defaultLocale;
    } else {
      return locale;
    }
  }, [queryLocale, propLocale]);

  const [locale, setLocale] = useState<LocaleContext['locale']>(finalLocale);

  useEffect(() => {
    if (locale && process.browser) {
      cookie.set('locale', locale, { expires: 1 });
    }
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        hasProvider: true,
        locale,
        setLocale
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => useContext(LocaleContext);

/**
 * A hook that returns the current locale
 */
export const useLocale = () => {
  const { locale } = useLocaleContext();
  return useMemo(() => locale, [locale]);
};
