import flatten from 'flat';
import en from './en.json';

import '@formatjs/intl-pluralrules/polyfill-locales';

import momentEn from 'moment/locale/en-gb';
import env from '../src/env';

export type LocaleEnum = 'en';

export type Translation = {
  [key in LocaleEnum]: { [key: string]: string };
};

export const momentLocales = {
  en: { code: 'en-gb', locale: momentEn }
};

const locales: Translation = {
  en: flatten(en)
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unflattennedLocales: any = {
  en
  // fr
};

export const defaultLocale = (env().NEXT_PUBLIC_DEFAULT_LOCALE || 'en') as LocaleEnum;

export default locales;
